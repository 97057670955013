@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* global components  */

body {
    font-family: 'DM Sans', sans-serif;
}

h1,h2,h3, .heading {
    font-family: 'futura', sans-serif;
}

.primary-block, .secondary-block {
  padding: 1rem 8rem;
  margin: -1px 0px;
}

@media (max-width: 767px) {
  .primary-block, .secondary-block {
    padding: 2rem 2rem;
  }
}

.dark .primary-block {
    background-color: #252422;
}

.dark .primary-block .text {
    color: white;
    border-color: white;
}

.dark .secondary-block {
    background-color: white;
}

.dark .secondary-block .text {
    color: #595959;
    border-color: #595959;
}

.dark .secondary-block .strong-text, .dark .secondary-block strong {
  color: #020202;
  border-color: #020202;
}

.light .primary-block {
    background-color: white;
}

.light .primary-block .text {
    color: #595959;
    border-color: #595959;
  }

.light .primary-block .strong-text, .dark .secondary-block strong {
  color: #020202;
  border-color: #020202;
}

.light .secondary-block {
    background-color: #252422;
}

.light .secondary-block .text {
    color: white;
    border-color: white;
}

.colored-seperator {
  background: linear-gradient(0.25turn, #28D08A, #2FE1D7, #2B62CC);
  width: 100%;
  height: 15px;
}

.rich-text p {
  margin: 15px 0px;
}

.rich-text a {
  text-decoration: underline;
}

/* nav bar  */

/* theme switch */
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
  }
  
  /* Hide default checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2B62CC;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  /* The slider 'ball' */
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: #c4c4c4;
    -webkit-transition: .4s;
    transition: .4s;

  }

  /* When input active */
  input:checked + .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: #fcd226;
    -webkit-transition: .4s;
    transition: .4s;

  }
  
  input:checked + .slider {
    background-color: #45abc4;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #45abc4;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  /* show scrollbar on vertical search */

  .vertical-scroll::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  .vertical-scroll::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgb(255, 255, 255);
      box-shadow: 0 0 1px rgba(255,255,255,.5);
  }

/* Welcome  */

.animated-text {
  background: linear-gradient(0.25turn, #28D08A 0%, #2FE1D7 25%, #2B62CC 50%, #2FE1D7 75%, #28D08A 100%);
  background-size: 400%;
  outline: none;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: animate 20s linear infinite;
}
@keyframes animate {
  to{
    background-position: 400%;
  }
}

/* Search input */

.dark .search-input {
  border-color: #fff;
}

.light .search-input {
  border-color: #000;
}

.dark .fillable-svg svg path {
  fill: #fff;
}

.light .fillable-svg svg path {
  fill: #000;
}